

































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MPartnerItem: () => import("@/components/molecules/m-partner-item.vue"),
  },

  props: {
    type: {
      type: String,
      required: false,
      default: "list",
    },
    search: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root }) {
    const state = reactive({
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "image", text: "", width: 1 },
        { value: "name", text: root.$tc("layout.misc.name") },
        { value: "website", text: root.$tc("layout.misc.website") },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: "",
      searchTimeout: undefined as any,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("partner", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            search: state.search || undefined,
          },
        })
        .then(({ data: { partners, total } }) => {
          state.empty = false;
          state.items = partners.map((partner: any, index: number) => ({
            id: index,
            name: "",
            city: "",
            ...partner,
          }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`partner/${item.id}`)
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    watch(
      () => props.search,
      (newSearch) => {
        state.search = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const paginationLength = computed(() =>
      Math.ceil(state.total / state.options.itemsPerPage)
    );
    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/partner/`
    );

    const copyPartner = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`partner/${id}`)
        .then(({ data: { partner } }) => {
          state.loading = true;

          const name = prompt(
            root.$tc("panel.globalTools.partner.enterNewPartnerName"),
            `${partner.name} (kopia)`
          );

          if (name) {
            axiosInstance
              .post("partner", {
                name,
                website: partner.website || undefined,
                image: partner.image?.id || undefined,
              })
              .then(fetchData)
              .catch((error) => {
                console.log(error);
                alert(root.$tc("panel.globalTools.partner.newPartnerError"));
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            state.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
        });
    };

    return {
      state,
      fetchData,
      paginationLength,
      deleteItem,
      filesUrl,
      copyPartner,
    };
  },
});
